import React, { useContext } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import ToggleMute from "../components/ToggleMute"

export default function ContractorAgreementB() {
  const { user, saveUser } = useContext(UserContext)

  const saveField = e => {
    saveUser({
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Background container>
      <div className="d-flex flex-column align-items-center">
        <h1 className="font-weight-bold mt-4 ml-4">Contractor Agreement</h1>

        <p className="p-4 lead" style={{ marginBottom: 100 }}>
          <strong>7)</strong> I acknowledge and agree that as an independent
          contractor I am personally responsible for the performance of the
          Services, regardless of whether actually performed by myself or my
          Substitute, and accordingly accept all liability for any costs,
          claims, proceedings, damages, losses, expenses or other liabilities
          (including legal costs) (together, “Losses”) threatened, suffered or
          incurred by ALPHAVIBE and/or the Customer as a result in part/whole of
          my negligent provision of the Services or failure to ensure that the
          Services are provided with a sufficient standard of care, including
          but not limited to:
          <br />
          &nbsp;&nbsp;<strong>i.</strong> Failing to attend at the Customer’s
          designated workplace on the First day of Work or cancellation of the
          job booking before the First day of Work, in which I shall be liable
          to pay a default sum of SGD 30, or SGD 50 if fee of the contract job
          is greater or equal to SGD 10 per hour, as liquidated damages to
          ALPHAVIBE;
          <br />
          <br />
          &nbsp;&nbsp;<strong>ii.</strong> Missing or failing to attend to any
          job booked without informing respective coordinator 48 hours
          beforehand with acknowledgement, in which I shall be liable to pay
          ALPHAVIBE a default penalty of SGD 30, or SGD 50 if fee of the
          contract job is greater or equal to SGD 10 per hour;
          <br />
          <br />
          &nbsp;&nbsp;<strong>iii.</strong> Being discharged by the Customer in
          part of my tardiness, in which I shall be liable to pay ALPHAVIBE a
          default penalty of SGD 10, or SGD 20 if fee of the contract job is
          greater or equal to SGD 10 per hour;
          <br />
          <br />
          &nbsp;&nbsp;<strong>iv.</strong> The need for rectification for
          non-adherence to the Grooming Standards as per set out in Appendix 2,
          in which I shall be liable to pay ALPHAVIBE a default penalty of SGD
          10, or SGD 20 if fee of the contract job is greater or equal to SGD 10
          per hour;
        </p>

        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="Next"
            size="medium"
            onClick={() => navigate("/contractor-agreement-c")}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/contractor-agreement-a")}
          />
        </div>
      </div>
    </Background>
  )
}
